@import "~animate.css/animate.min.css";

.navbar-title {
  font-size: 1.2rem;
  font-weight: bold;
  font-variant: small-caps;
  text-shadow: #777 2px 2px;
}

.location-search-input {
  width: 100%;
  border: 1px solid #333;
  border-radius: 10px;
  height: 75px;
  font-size: 30px;
  padding: 15px;
  color: #555;
  outline-style:none;
}

.address-bar-title {
  text-align: center;
  margin-bottom: 3rem;

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
}

.address-search-container {
  padding-top: 10%;
  transition: padding-top 1s;

  &.done {
    padding-top: 10px;

    .location-search-input {
      border: none;
      transition: font-size 0.25s;

      &:focus {
        border: 1px solid #333;
      }
    }
  }
}

.autocomplete-dropdown-container {
  border: 1px solid #555;
  border-radius: 10px;
  padding: 5px;
  margin-top: 5px;

  &:empty {
    display: none;
  }
}

.quote {
  text-align: center;
  color: #444;
  font-style: italic;
  margin: 2rem;
}

.icon-text {
  margin-left: 10px;
}

.icon-text-left {
  margin-right: 10px;
}

.search-results-wrapper {
  margin-bottom: 15rem;
}

.card-deck {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: .5rem;
}

.official-card {
  .official-profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .official-name {
    font-weight: bold;
    font-size: 1.25em;
  }

  .office-name {
    opacity: 0.9;
    font-weight: bold;
    font-style: italic;
    font-size: 1.1rem;
  }

  address {
    font-size: 0.75rem;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 20px;
  background-color: #212529;

  .container {
    color: #ccc;

    a {
      color: #ccc;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
